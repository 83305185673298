import BaseBean from "@/utils/BaseBean";

export interface IPdfJsEngineDataObj {
    utilInst:PdfJsEngineUtil
    pdfJsEngineParams:any
}
export default class PdfJsEngineUtil extends BaseBean{
    public dataObj:IPdfJsEngineDataObj;

    constructor(proxy:any,dataObj:IPdfJsEngineDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    public initPdfJsEngineParams(pdfJsEngineParams:any):void{
        this.dataObj.pdfJsEngineParams = Object.assign({source: '',src:'/plugins/pdf/web/viewer.html?file='}, pdfJsEngineParams);
    }
}